const generalUtilities = {
	uuidv4() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
		.replace(/[xy]/g, function (c) {
			const r = Math.random() * 16 | 0, 
				v = c == 'x' ? r : (r & 0x3 | 0x8);
			return v.toString(16);
		});
	},

	formatLastSeen(timestamp) {
		const formattedTime = moment(timestamp).format("hh:mm A");
		const differenceInDays = moment().diff(moment(timestamp), 'days');

		if (differenceInDays === 0) {
			return `Last seen today at ${formattedTime}`;
		} else if (differenceInDays === 1) {
			return `Last seen yesterday at ${formattedTime}`;
		} else {
			return `Last seen ${differenceInDays} days ago`;
		}
	},

	formatTimestamp(timestamp, isUTC=false) {
		const momentDate = moment(new Date(timestamp).toISOString());
		return (isUTC ? momentDate.utc().format("MMM DD, YYYY @ hh:mma [UTC]") : momentDate.format("MMM DD, YYYY @ hh:mma"))
	}
}

export default generalUtilities
